import { useEffect, useRef, useState, useContext } from "react";
import AsbekAppwriteService from "../api/AsbekAppwriteService";
import { CoinContext } from "../contextdtatashare/CoinContext";

const appwriteService = new AsbekAppwriteService();

export const AdsgramTask = () => {
  const { incrementTaskEarn } = useContext(CoinContext);
  const debug = false;
  const blockId = "task-7821";
  const taskRef = useRef(null);
  const tg = window.Telegram.WebApp;
  tg.ready();

  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;
  const userId = String(usertg?.id).trim();

  // State used to remount the <adsgram-task> element and load a new task.
  const [taskKey, setTaskKey] = useState(0);

  const saveCoinsToServer = async (taskEarn) => {
    try {
      // Fetch current document
      const userDoc = await appwriteService.getDocument(userId);
      const currentTaskEarn = parseFloat(userDoc.taskEarn) || 0;
      const newTaskEarn = currentTaskEarn + parseFloat(taskEarn);
      const now = Math.floor(Date.now() / 1000);

      await appwriteService.updateDocument(userId, {
        taskEarn: newTaskEarn,
      });
      incrementTaskEarn(taskEarn);
    } catch (error) {
      console.error("Error updating user data:", error, "for user ID:", userId);
    }
  };

  useEffect(() => {
    // Handler for when the reward event fires (i.e. when the task is completed)
    const rewardHandler = (event) => {
      // Earn 500 coins when the reward event fires
      
    };

    const task = taskRef.current;
    if (task) {
      task.addEventListener("reward", rewardHandler);
    }

    return () => {
      if (task) {
        task.removeEventListener("reward", rewardHandler);
      }
    };
  }, [userId, incrementTaskEarn]);

  // Function to load a new task by incrementing the taskKey.
  const loadNewTask = () => {
   
    setTaskKey((prevKey) => prevKey + 1);
    saveCoinsToServer(500);
  };

  if (!customElements.get("adsgram-task")) {
    return null;
  }

  return (
    <>
      <style>{`
        adsgram-task.task {
          border: 1px solid #ccc;
          display: block;
          padding: 10px;
          border-radius: 5px;
          margin: 10px 0;
        }
        adsgram-task .reward {
          font-weight: bold;
          color: gold;
          display: block;
          margin-bottom: 8px;
        }
        adsgram-task .button {
          background-color: blue;
          color: white;
          padding: 5px 10px;
          cursor: pointer;
          display: inline-block;
          margin-right: 10px;
          border-radius: 3px;
        }
        adsgram-task .button_done {
          background-color: green;
          color: white;
          padding: 5px 10px;
          cursor: pointer;
          display: inline-block;
          border-radius: 3px;
        }
      `}</style>

      <adsgram-task
        key={taskKey}  
        className="task"
        data-block-id={blockId}
        data-debug={debug}
        ref={taskRef}
      >
        <span slot="reward" className="reward">
          Earn 500 
        </span>
        <div slot="button" className="button">
          go
        </div>
        {/* Attach an onClick handler on the done slot to load a new task */}
        <div slot="done" className="button_done" onClick={loadNewTask}>
          done
        </div>
      </adsgram-task>
    </>
  );
};
