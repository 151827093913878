import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from './contextdtatashare/CoinContext';
import { GiveLifeButton } from './asbek/earn_style';
import styled from 'styled-components';

import { initializeAd, playAd } from './Ads/VideoAdComponent';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  `;
  
   const InfoText = styled.p`
  font-size: 0.75rem;
  color: #333;
  text-align: center;
  margin: 8px 0;
  line-height: 1.3;
  font-weight: 300;
  letter-spacing: 0.5px;
  opacity: 0.8;

  a {
    color: #fcb69f;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AdsComponent = ({ blockId }) => {
  const [adController, setAdController] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {appConfig, localClickCount,    localCoinValue,    setLocalClickCount,    setLocalCoinValue,} = useContext(CoinContext);
  const [isclicked, setIsClicked] = useState(false);
  const tg = window.Telegram.WebApp;
  tg.ready();
  const initDataUnsafe = tg.initDataUnsafe;
  const usertg = initDataUnsafe?.user;

    // Optional story sharing parameters

    // Share the story

    ////console.log('User found:', usertg); // Log user details
    
    
    const userId = String(usertg?.id).trim();
    
// Effect function
useEffect(() => {
  if (window.show_8770016) {
      return
  }

  const tag = document.createElement('script')

  tag.src = '//niphaumeenses.net/vignette.min.js'
  tag.dataset.zone = '8770016'
  tag.dataset.sdk = 'show_8770016'

  document.body.appendChild(tag)
}, [])



      
useEffect(() => {
  // Initialize the ad engine when the component mounts
  initializeAd();
}, []);

useEffect(() => {
  if (window.show_8489152) {
    setScriptLoaded(true);
    setIsLoading(false);
    return;
  }

  // Create a new script tag to load the ad script
  const tag = document.createElement('script');
  tag.src = '//niphaumeenses.net/vignette.min.js';
  tag.dataset.zone = '8489152';
  tag.dataset.sdk = 'show_8489152';

  // Append the script to the document body
  document.body.appendChild(tag);

  // Handle when the script is successfully loaded
  tag.onload = () => {
    setScriptLoaded(true);
    setIsLoading(false); // Hide the loading state
  };

  // Handle errors if the script fails to load
  tag.onerror = () => {
    setIsLoading(false);
    console.error('Failed to load ad script.');
  };
}, []);


  const showAd = async (add) => {
    //console.log('show ad')
    if (scriptLoaded && window.show_8489152) {
      // Call the global function to show the ad
      window.show_8489152().then(async () => {
        // Add the function to be executed after the ad is shown
        setIsClicked(false)
        if(add){
          setLocalClickCount((prevCount) => {
            const newClickCount = prevCount + 1;
            localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
            return newClickCount;
          });
        }
        
        if(!appConfig.adgramAds){
          setLocalCoinValue((prevCount) => {
            const newCoinValue = prevCount + 150;
            localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
            return newCoinValue;
          });
        } else {
          setLocalCoinValue((prevCount) => {
            const newCoinValue = prevCount + 50;
            localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
            return newCoinValue;
          });
          await handleAdsgramDisplay()
        }
        if(appConfig.onClickaAds){
          setLocalCoinValue((prevCount) => {
            const newCoinValue = prevCount + 100;
            localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
            return newCoinValue;
          });
          await playAd(userId, setLocalCoinValue);
        }
        //await playAd(userId, setLocalCoinValue);
      }).catch(async (error) => {
        console.error("Error while showing ad:", error);
        if(appConfig.adgramAds){
          await handleAdsgramDisplay(1)
        }
        if(appConfig.onClickaAds){
          setLocalCoinValue((prevCount) => {
            const newCoinValue = prevCount + 100;
            localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
            return newCoinValue;
          });
          await playAd(userId, setLocalCoinValue, setLocalClickCount);
        }
        //await playAd(userId, setLocalCoinValue, setLocalClickCount);
        setIsClicked(false)
      });
    } else {
      console.error("some while showing ad:", error);
      if(appConfig.adgramAds){
        await handleAdsgramDisplay(1)
      }
      if(appConfig.onClickaAds){
        setLocalCoinValue((prevCount) => {
          const newCoinValue = prevCount + 100;
          localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
          return newCoinValue;
        });
        await playAd(userId, setLocalCoinValue, setLocalClickCount);
      }
      //await playAd(userId, setLocalCoinValue, setLocalClickCount);
      setIsClicked(false)
    }
  };

  useEffect(() => {
    const checkAdsgramLoaded = () => {
      //console.log('herekj')
      if (window.Adsgram) {
        //console.log('window.')
        try {
          const controller = window.Adsgram.init({
            blockId,
            //debug: false, // set to false in production
          });
          setAdController(controller);
          // setMessage('Adsgram SDK initialized successfully.');
        } catch (e) {
          //console.log('why', e)
          // setError('Failed to initialize Adsgram SDK.');
        }
      } else {
        setTimeout(checkAdsgramLoaded, 100);
      }
    };
    checkAdsgramLoaded();
  }, [blockId]);

  const handleAdDisplay = async () => {
    setIsClicked(true)
    showAd(1)
    
    // if (adController) {
    //   adController.addEventListener('onBannerNotFound', (e) => {
    //     //console.log('No ads available at the moment', e);
    //     // Handle no banner found (suppress alert or custom handling)
    //   });

    //   adController.addEventListener('onError', (e) => {
    //     //console.log('onError', e);
    //     // Handle no banner found (suppress alert or custom handling)
    //   });

    //   adController.addEventListener('onNonStopShow', (e) => {
    //     //console.log('onNonStopShow', e);
    //     // Handle no banner found (suppress alert or custom handling)
    //   });
    //   //console.log('controller')
      
    //   adController.show()
    //     .then((result) => {
    //       //console.log('lets go')
    //         setLocalClickCount((prevCount) => {
    //           const newClickCount = prevCount + 1;
    //           localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
    //           return newClickCount;
    //         });
      
    //         setLocalCoinValue((prevCount) => {
              
    //           const newCoinValue = prevCount + 20;
    //           localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
    //           return newCoinValue;
    //         });
    //         //console.log('dancing')
    //         showAd()
    //         // setMessage('Thank you for watching the ad!');
          
    //     })
    //     .catch((result) => {
    //       //console.log('jumbing')
    //       showAd(1)
          
    //       // setError(`Ad error or skipped: ${result.description}`);
    //     })
    //     .finally(() => {
    //       //console.log('finishing')
    //       // setMessage('Ad finished showing.');
    //     });
    // } else {
    //   showAd(1)
    // }
  }
  const handleAdsgramDisplay = async (add) => {
    // setIsClicked(true)
    
    if (adController) {
      adController.addEventListener('onBannerNotFound', (e) => {
        //console.log('No ads available at the moment', e);
        // Handle no banner found (suppress alert or custom handling)
      });

      adController.addEventListener('onError', (e) => {
        //console.log('onError', e);
        // Handle no banner found (suppress alert or custom handling)
      });

      adController.addEventListener('onNonStopShow', (e) => {
        //console.log('onNonStopShow', e);
        // Handle no banner found (suppress alert or custom handling)
      });
      //console.log('controller')
      
      adController.show()
        .then((result) => {
          //console.log('lets go')
          if(add){

            setLocalClickCount((prevCount) => {
              const newClickCount = prevCount + 1;
              localStorage.setItem(`localClickCount_${userId}`, newClickCount); // Save to localStorage on each click
              return newClickCount;
            });

          }
      
            setLocalCoinValue((prevCount) => {
              
              const newCoinValue = prevCount + 100;
              localStorage.setItem(`localCoinValue_${userId}`, newCoinValue);
              return newCoinValue;
            });
            //console.log('dancing')
            // showAd()
            // setMessage('Thank you for watching the ad!');
          
        })
        .catch((result) => {
          //console.log('jumbing')
          // showAd(1)
          
          // setError(`Ad error or skipped: ${result.description}`);
        })
        .finally(() => {
          //console.log('finishing')
          // setMessage('Ad finished showing.');
        });
    } else {
      // showAd(1)
    }
  }
  return (
    <div style={{ marginBottom: '10px' }}>
      <InfoText >
      Watch ads and earn free Asbeks! Tap the button below to get started.
      </InfoText>
      <GiveLifeButton 
      onClick={(e) => {handleAdDisplay() }} 
     disabled={isclicked } // Disable button if waiting for network or fetching coin value
    >
    {/* {theWeeklyClickCount === null && !isOnline ? 'Waiting for network...' : 'EARN ASBEKS +70'} */}
    EARN ASBEKS + 150
    </GiveLifeButton>
      {/* {message && <p>{message}</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>} */}
    </div>
  );

};

export default AdsComponent;
