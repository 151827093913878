import React, {useContext, useState, useEffect } from 'react';
import { CoinContext } from '../contextdtatashare/CoinContext';
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import AsbekAppwriteService from '../api/AsbekAppwriteService';
import axios from 'axios';
import BuyAsbeks from '../tonpython/buyasbeks';
import {faShoppingCart , faPaperPlane, faArrowDown, faSyncAlt, faQrcode, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal'; // For the bottom sheet effect
import { QRCodeCanvas } from 'qrcode.react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';
import AsbeksPrice from '../asbek/AsbeksPrice';
import WithdrawCard from '../tonpython/withdraw/WithdrawCard';
import TonToAsbeksSwap from '../tonpython/ConnectPage';
import DailyWithdrawCard from '../tonpython/withdraw/DailyWithdraw';

const WalletCard = styled.div`
    background: linear-gradient(120deg, #4f94d4, #81d4fa);
    padding: 30px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  `;

  const BalanceContainer = styled.h1`
    font-size: 52px;
    margin: 10px 0;
  `;
  const BalanceUSD = styled.p`
  font-size: 18px;
  color: #f1f1f1;
`;

const appwriteService = new AsbekAppwriteService();

const WalletInfo = () => {
  const wallet = useTonWallet();
  const userFriendlyAddress = useTonAddress(true);
  const [Balance, setBalance] = useState(null); // State to store  balance
  const [balanceInUsd, setBalanceInUsd] = useState(null); 
  const [errorMessage, setErrorMessage] = useState(null);
  //console.log('kjhgfhsjdk', userFriendlyAddress)
  
  useEffect(() => {
  if (wallet) {
    

    const fetchWalletInfo = async () => {
      const data = window.Telegram.WebApp.initData;
      const initdata =
        'user%3D%257B%2522id%2522%253A445929610%252C%2522first_name%2522%253A%2522%25F0%259F%2591%2591Abdurazak%25F0%259F%2591%2591%2522%252C%2522last_name%2522%253A%2522%2522%252C%2522username%2522%253A%2522hakaaf%2522%252C%2522language_code%2522%253A%2522en%2522%252C%2522is_premium%2522%253Atrue%252C%2522allows_write_to_pm%2522%253Atrue%257D%26chat_instance%3D4195751860943568501%26chat_type%3Dprivate%26auth_date%3D1729075283%26hash%3Da3d3539cc52da6af33d92bb289798907181906e588fbb778503d561cade9106d';

      const url = `https://67100c95ea75afe346f4.appwrite.global/walletInfo?thewallet=${encodeURIComponent(userFriendlyAddress)}&initData=${data}&asbeksValue=1000&value=1`;

      try {
        const response = await axios.get(url, {
          timeout: 60000,
        });

        if (response.data.status === 'true') {
          setBalance(response.data.balance); // Update  balance
          setBalanceInUsd(response.data.balanceInUsd); // Update balance in USD
        } else {
          setErrorMessage('Failed to fetch wallet info.');
        }
      } catch (error) {
        console.error('Error fetching wallet info:', error);
        setErrorMessage('Error fetching wallet info.');
      }
    };

    fetchWalletInfo();
  }
}, [wallet, userFriendlyAddress]);

if (!wallet) {
  return <p></p>;
}
  return (
    <div>
      <center>
      <WalletCard>
      <BalanceContainer>

      {Balance ? (
              isNaN(Number(Balance)) ? 'N/A' : Number(Balance).toFixed(6) + ' TON'
            ) : (
              <Skeleton variant="text" width={100} />
            )}
      
      </BalanceContainer>
      <BalanceUSD>
      {balanceInUsd ? (
            '≈ $' + isNaN(Number(balanceInUsd)) ? ('≈ $' + Number(balanceInUsd).toFixed(2) + ' USD') : (
                '≈ $' + Number(balanceInUsd).toFixed(2) + ' USD'
              )
            ) : (
              <Skeleton variant="text" width={100} />
            )}
      </BalanceUSD>
      </WalletCard>
          
    {/* <BuyAsbeks/> */}
      {/* <p>Connected Wallet: {wallet.name}</p>
      <p>Device: {wallet.device.appName}</p>
      <p>User-friendly Address: {userFriendlyAddress}</p> */}
      </center>
    </div>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #f7f9fc;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  border-radius: 20px; /* Circular button */
  
`;

const WalletButton = styled.button`
  background: linear-gradient(145deg, #007aff, #005bb5); /* Gradient background */
  color: white;
  border: none;
  border-radius: 50px; /* Circular button */
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2); /* Soft shadow for depth */
  display: flex;
  align-items: center;
  gap: 10px; /* Space between the logo and the address */
  
  /* Hover effect with smooth scaling */
  &:hover {
    background: linear-gradient(145deg, #005bb5, #007aff);
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.4);
  }

  img {
    width: 25px; /* Set the size of the logo */
    height: 25px;
    border: 2px solid white; /* White stroke around the image */
    border-radius: 50%; /* Ensure the stroke follows the circular shape */
  }
`;

const StatusText = styled.span`
  font-size: 14px;
  color: ${(props) => (props.connected ? '#28a745' : '#dc3545')}; /* Green if connected, red if not */
  margin-left: 10px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  flex-direction: column;
  min-width: 160px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: auto; /* Ensure pointer events are enabled when dropdown is visible */

  /* Keyframes for the dropdown animation */
  @keyframes dropdown-animation {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }

  button {
    background: none;
    border: none;
    text-align: left;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    color: #333333;
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;
    gap: 12px; /* Space for icons */
    
    /* Hover effect with subtle background */
    &:hover {
      background-color: #f0f4f8;
      transform: translateX(4px);
    }
  }
`;



export const Header = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const userFriendlyAddress = useTonAddress(true);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [currentPage, setCurrentPage] = useState('wallet');
  const wallet = useTonWallet();

  const truncateAddress = (address) =>
    address ? `${address.slice(0, 6)}...${address.slice(-6)}` : '';

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(userFriendlyAddress);
    
    setDropdownVisible(false);
  };

  const handleDisconnect = () => {
    tonConnectUI.disconnect();
    setDropdownVisible(false);
  };
  
  return (
    <HeaderContainer>
            {wallet ? (
        <>
          <WalletButton onClick={() => setDropdownVisible(!dropdownVisible)}>
            <img src="https://cryptologos.cc/logos/toncoin-ton-logo.png?v=035" alt="TON Logo" />
            {truncateAddress(userFriendlyAddress)}
          </WalletButton>
          {dropdownVisible && (
  <DropdownMenu visible={dropdownVisible}>
    <button onClick={handleCopyAddress}>Copy Address</button>
    <button onClick={handleDisconnect}>Disconnect</button>
  </DropdownMenu>
)}
        </>
      ) : (
        <WalletButton onClick={() => tonConnectUI.openModal()}>
           <img src="https://cryptologos.cc/logos/toncoin-ton-logo.png?v=035" alt="TON Logo" />
          Connect Wallet
        </WalletButton>
      )}
    </HeaderContainer>
  );
};

const WalletConnectPage = () => {
  const [currentPage, setCurrentPage] = useState('wallet'); // Default page is 'wallet'

  if (currentPage === 'dailyWithdraw') {
    return (
      <div>
        <button onClick={() => setCurrentPage('wallet')}>Back to Wallet</button>
        <TonConnectUIProvider>
        <DailyWithdrawCard />
        </TonConnectUIProvider>
      </div>
    );
  }


  return (
    <div style={{ marginBottom: '80px' }}>
      <center>
      <button onClick={() => setCurrentPage('dailyWithdraw')}>Go to Daily Withdraw</button>

    <TonConnectUIProvider manifestUrl="https://asbeks.github.io/tonconnect-manifest.json">
    {/* <TonConnectButton style={{ marginBottom: '20px' }} /> */}
    <Header></Header>
      <WalletInfo />
      <AsbeksPrice/>
      <TonToAsbeksSwap></TonToAsbeksSwap>  
      <WithdrawCard></WithdrawCard>
    </TonConnectUIProvider>
    
    </center>
    </div>
  );

};

export default WalletConnectPage;
